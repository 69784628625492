import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import Title from "../../atoms/Title/Title";
import StyledButton from "../../atoms/StyledButton/StyledButton";

interface Props {
  children?: JSX.Element;
  title?: string;
  button?: string;
  clearFilterButton?: string;
  onClickClearFilterButton?: () => void;
  onClickfirmwaresWithoutFeature?: () => void;
  onClickCross?: () => void;
  firmwaresWithoutFeature?: {
    id: number;
    name: string;
  }[];
  link?: string;
  cross?: boolean;
}

const PageTemplate = ({
  children,
  title,
  button,
  clearFilterButton,
  onClickClearFilterButton,
  onClickfirmwaresWithoutFeature,
  onClickCross,
  firmwaresWithoutFeature,
  link,
  cross,
}: Props) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (link) {
      navigate(link);
    }
  };

  const handleGoBackHistory = () => navigate(-1);

  return (
    <Box className="PageTemplate">
      {(title || button) && (
        <Box className="titleAndButton">
          {title && <Title variant="h1">{title}</Title>}
          <Box className="alignButtons">
            {firmwaresWithoutFeature && firmwaresWithoutFeature.length > 0 && (
              <StyledButton
                onClick={onClickfirmwaresWithoutFeature}
                className="cancel"
              >
                {"Firmwares without feature"}
              </StyledButton>
            )}
            {clearFilterButton && (
              <StyledButton onClick={onClickClearFilterButton}>
                {clearFilterButton}
              </StyledButton>
            )}
            {button && (
              <StyledButton onClick={() => handleNavigation()}>
                <AddIcon />
                {button}
              </StyledButton>
            )}
          </Box>
          {cross && (
            <Box className="closingCross">
              <CloseIcon
                onClick={onClickCross ? onClickCross : handleGoBackHistory}
              />
            </Box>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default PageTemplate;
