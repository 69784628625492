import { Chip } from "@mui/material";
import tinycolor from "tinycolor2";
import stc from "string-to-color";

interface Props {
  label: string;
  className?: string;
  color?:
    | "info"
    | "default"
    | "error"
    | "success"
    | "primary"
    | "secondary"
    | "warning";
  randomColor?: boolean;
  onDelete?: (event: any) => void;
}

const StyledChip = ({
  label,
  className,
  color,
  onDelete,
  randomColor,
}: Props) => {
  const coloRandomlyChoosen = stc(label ?? "");
  const tinyRandomColor = tinycolor(coloRandomlyChoosen);

  return (
    <Chip
      className={`StyledChip ${className}`}
      label={label}
      color={color}
      onDelete={onDelete}
      sx={
        randomColor
          ? {
              backgroundColor: coloRandomlyChoosen,
              "& .MuiChip-label": {
                color: `${
                  tinyRandomColor.isLight() ? "black" : "white"
                } !important`,
              },
            }
          : undefined
      }
    />
  );
};

export default StyledChip;
