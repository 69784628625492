import { Box, Modal } from "@mui/material";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import StyledButton from "../../atoms/StyledButton/StyledButton";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  action?: (event: React.MouseEvent<HTMLElement>) => void;
  title?: string;
  text?: string;
  children?: JSX.Element;
}

const FirmwareWithoutFeatureModal = ({
  isOpen,
  handleClose,
  action,
  title,
  text,
  children,
}: Props) => {
  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    if (action) {
      action(event);
    }
    handleClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <>
        <StyledPaper className="FirmwareWithoutFeatureModal">
          <Title variant="h1">{title}</Title>
          <Text>{text}</Text>
          {children}
          <Box className="buttonsBox">
            <StyledButton onClick={handleDelete}>{"close"}</StyledButton>
          </Box>
        </StyledPaper>
      </>
    </Modal>
  );
};

export default FirmwareWithoutFeatureModal;
