import {
  Firmwares,
  CreateFirmware,
  Firmware,
  CreateFirmwareResponse,
} from "../../../types/rtk/software/firwmare/firwmare";
import { apiSlice } from "../../_api/api-slice";

export const firmware = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFirmwares: build.query<Firmwares, string>({
      query: (url) => ({
        url: `firmware${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Firmwares" }],
    }),
    getFirmwaresWithoutFeature: build.query<
      {
        id: number;
        name: string;
      }[],
      void
    >({
      query: () => ({
        url: `firmware-without-feature`,
        method: "get",
      }),
    }),
    getFirmwareById: build.query<Firmware, string>({
      query: (id) => ({
        url: `firmware/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "Firmware", id: `Firmware_${id}` },
      ],
    }),
    createFirmware: build.mutation<CreateFirmwareResponse, CreateFirmware>({
      query: (body) => ({
        url: `firmware`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Firmwares" }],
    }),
    deleteFirmware: build.mutation<null, string>({
      query: (id) => ({
        url: `firmware/${id}`,
        method: "delete",
      }),
      invalidatesTags: (_result, _error, _id) => [{ type: "Firmwares" }],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetFirmwaresQuery,
  useGetFirmwaresWithoutFeatureQuery,
  useGetFirmwareByIdQuery,
  useCreateFirmwareMutation,
} = firmware;
